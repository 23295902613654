import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import styles from '../styles.module.css'
import { useSelector } from 'react-redux';
import moment from 'moment';


export default function TeacherInfo() {
  
  const { displayname, category, category_sovmest, cert_date, cert_date_sovmest, photo } = useSelector(state => state.auth)
  
  const plan_cert_date = cert_date_sovmest
    ? moment(cert_date_sovmest, 'DD.MM.YYYY').add(5, 'y').format('DD.MM.YYYY')
    : moment().year()

  const renderPhoto = () => {
    if (photo) return <img src={photo} alt="о_О"/>
    else return <div>Фотография не загружена</div>
  }

  return (
    <ListGroup className={`${styles.teacherInfoBlock} mb-4`}>
      <ListGroup.Item variant="primary" className={styles.teacherInfoTitle}>Личные данные</ListGroup.Item>
      <ListGroup.Item  className={styles.teacherPhoto}>{renderPhoto()}</ListGroup.Item>
      <ListGroup.Item className={styles.teacherInfoItem}>
        <div>ФИО:</div>
        <div>{displayname}</div>
      </ListGroup.Item>
      <ListGroup.Item className={styles.teacherInfoItem}>
        <div>Категория:</div>
        <div>{category_sovmest}</div>
      </ListGroup.Item>
      <ListGroup.Item className={styles.teacherInfoItem}>
        <div>Дата последней аттестации:</div>
        <div>{cert_date_sovmest}</div>
      </ListGroup.Item>
    </ListGroup>
  )
}
