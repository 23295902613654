import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import RequestForm from './Components/RequestForm'
import RequestsTable from './Components/RequestsTable'
import { useDispatch, useSelector } from 'react-redux'
import { getSpecializations, getRequests, removeRequestsList } from '../../actions/common'
import { getOrganizations } from '../../actions/auth'
import Loader from '../../components/Loader'
import { useNavigate } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import Button from '../../components/Button'
import Reports from './Components/Reports'
import { BACKEND_DOMAIN } from '../../assets/const'
import { FaFilePdf } from "react-icons/fa";



export default function Expert() {
  
  const { specializations, requestsList, loading } = useSelector(state => state.common)
  const { organizations, expert, edu_expert, org } = useSelector(state => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  useEffect(() => {
    !specializations && dispatch(getSpecializations())
    !organizations && dispatch(getOrganizations())
  }, [])

  const getRequestsList = (filters) => {
    dispatch(getRequests(filters))
  }
  
  const openRequest = (requestData) => () => {
    navigate(`/request/${requestData.id}`)
  }

  const sharedFilesClick = () => {
    navigate(`/shared_files`)
  }

  const getInstructionHandler = () => {
    const link = document.createElement('a')
    link.setAttribute('href', `${BACKEND_DOMAIN || ''}/help/Комиссия ОО.pdf`)
    link.setAttribute('target', '_blank')
    link.setAttribute('rel', 'noopener noreferrer')
    link.click()
  }

  return (
    <div className={styles.container}>
      <Col className={styles.butttonsBlock}>
        <Button label={"Методическая копилка"} onClick={sharedFilesClick}/>
        {expert && <Reports/>}
        {edu_expert &&
          <Button onClick={getInstructionHandler} style={{backgroundColor: 'red'}}>
            <FaFilePdf/>
            РУКОВОДСТВО ПОЛЬЗОВАТЕЛЯ
          </Button>
        }
      </Col>
      <RequestForm
        specializations={specializations}
        org={org}
        expert={expert}
        organizations={organizations}
        getRequestsList={getRequestsList}
      />
      {loading
          ? <Loader/>
          : !!requestsList && 
              <RequestsTable
                specializations={specializations}
                data={requestsList}
                expert={expert}
                openRequest={openRequest}
              />
      }
    </div>
  )
}
