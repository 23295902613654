import { chapters, chapters_musical } from "../assets/const"

export const groupCriterions = (criterions, other) => {
  
  if (!criterions) return null;

  const groupedCriterions = criterions.reduce((acc, criterion) => {
    if (acc.hasOwnProperty(criterion.chapter)) {
      acc[criterion.chapter].push(criterion)
    } else {
      acc[criterion.chapter] = [criterion]
    }
    return acc
  }, {})
  
  const chaptersList = other ? chapters_musical : chapters

  const result = chaptersList.reduce((acc, chapter) => {
    if (groupedCriterions.hasOwnProperty(chapter.value)) {
      acc.push({
        name: chapter.name,
        criterions: groupedCriterions[chapter.value]
      })
    }
    return acc
  }, [])
  
  return result
}