import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { approved } from "../utils/approve";
import { checkAndUpdateToken } from "./auth";
import { setExpertForm } from "./expert";
import { buildDefaultHeaders } from "./helper";
import { updateRequestStatus } from "./teacher";
import {
  REMOVE_COMMON_REQUEST_DATA,
  SET_COMMON_CRITERIONS,
  SET_COMMON_LOADING,
  SET_CURRENT_REQUEST,
  SET_FILES_LIST,
  SET_LESSONS,
  SET_REQUESTS_LIST,
  SET_REQUESTS_PARTS,
  SET_SPECIALIZATIONS,
  SET_TEACHER_LOADING,
  SET_TOPICS,
  SET_BANK,
  SET_BANK_LOADING,
  SET_BANK_COUNT,
  SET_COMMISSION_LIST,
  SET_COMMISSION_VALUES,
  SET_USER_INFO
} from "./types";


export const removeRequestsList = () => ({type: SET_REQUESTS_LIST, payload: null})

export const removeRequestData = () => ({type: REMOVE_COMMON_REQUEST_DATA})

export const removeFiles = () => ({type: SET_FILES_LIST, payload: null})

export const getSpecializations = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/certification/position`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_SPECIALIZATIONS, payload });
    }
  }
};

export const getRequests = (filters) => async (dispatch, getState) => {
  dispatch({type: SET_COMMON_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const params = {}
    filters && Object.keys(filters).forEach(key => filters[key] && (params[key] = filters[key]))
    const urlParams = new URLSearchParams(params).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/certification/request?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_REQUESTS_LIST, payload });
      dispatch(setExpertForm(filters))
    }
  }
  dispatch({type: SET_COMMON_LOADING, payload: false})
};

export const getCurrentRequest = (requestId, withoutLoading) => async (dispatch, getState) => {
  !withoutLoading && dispatch({type: SET_COMMON_LOADING, payload: true})
  try {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/certification/request/${requestId}`, {
        method: "GET",
        ...buildDefaultHeaders(getState)
      });
      if (response.ok) {
        const { expert, edu_expert } = getState().auth
        const isExpert = expert || edu_expert
        const respData = await response.json();
        const { position_id, org_id, cert_year, simple_cert } = respData
        const eduExpertComissionParams = { org_id, year: cert_year, request_id: requestId };
        const mainExpertComissionParams = { is_expert: true, year: cert_year, request_id: requestId };
        const comissionParams = edu_expert ? eduExpertComissionParams : mainExpertComissionParams
        dispatch({ type: SET_CURRENT_REQUEST, payload: respData });
        await dispatch(getCriterions(position_id, simple_cert));
        await dispatch(getRequestParts(requestId));
        await dispatch(getUserInfo(respData.user_id));
        isExpert && await dispatch(getComissionList(comissionParams));
      } else {
        dispatch({type: SET_COMMON_LOADING, payload: false})
      }
    }
  } catch (e) {
  } finally {
    dispatch({type: SET_COMMON_LOADING, payload: false})
  }
};

export const updateRequestReason = (requestId, reason) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
      let returnState
      try {
          const response = await fetch(`${BACKEND_URL_PREFIX}/certification/request_reason/${requestId}`, {
              method: "PUT",
              body: JSON.stringify({reason}),
              ...buildDefaultHeaders(getState)
          });
          if (response.ok) {
              createNotification('success', 'Успешно обновлено')
              returnState = true
          }
      } catch (ERR) {
          createNotification('error', 'Ошибка обновления')
          returnState = false
      } finally {
        return returnState
      }
  }
};

export const getCriterions = (position_id, simple_cert) => async (dispatch, getState) => {
  
  const criterionsEmpty = () => {
    createNotification('error', 'Не удалось загрузить критерии');
    dispatch({type: SET_COMMON_CRITERIONS, payload: null})
  }
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    try {
      const urlParams = new URLSearchParams({position_id, simple_cert}).toString();
      const response = await fetch(`${BACKEND_URL_PREFIX}/certification/criteria?${urlParams}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const payload = await response.json();
        const musicSchool = getState().auth.org == 8
        const other = position_id == 4 || position_id == 18
        payload.length ? dispatch({ type: SET_COMMON_CRITERIONS, payload, other }) : criterionsEmpty()
      } else {
        criterionsEmpty()
      }
    } catch (ERR) {
      criterionsEmpty()
    }
  }
};

export const getRequestParts = (reqestId) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/certification/request/${reqestId}/parts`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_REQUESTS_PARTS, payload });
    } else {
      createNotification('error', 'Не удалось загрузить критерии');
    }
  }
};

export const getLessons = (oid) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const orgId = oid || getState().auth.org
    const response = await fetch(`${BACKEND_URL_PREFIX}/lessons/${orgId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_LESSONS, payload });
    }
  }
};

export const getTopics = () => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/lms_file/topic`, {
      method: "GET",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: getState().auth.first_token
      }
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_TOPICS, payload });
    }
  }
};

export const getFiles = (params) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const urlParams = new URLSearchParams(params).toString();
    
    const response = await fetch(`${BACKEND_URL_PREFIX}/lms_file/files?${urlParams}`, {
      method: "GET",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: getState().auth.first_token
      }
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_FILES_LIST, payload });
    }
  }
};

export const getBank = (page = 0, onPage = 10, values) => async (dispatch, getState) => {
  dispatch({type: SET_BANK_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const offset = page * onPage;
    let params = {
      offset: offset,
      limit: onPage,
    };

    Object.keys(values).forEach(key => values[key] && (params[key] = values[key]));

    const urlParams = new URLSearchParams(params).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/shared_files/get_all?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_BANK, payload });
    }
  }
  dispatch({type: SET_BANK_LOADING, payload: false})
}

export const getBankCount = (page = 0, onPage = 10, values) => async (dispatch, getState) => {
  dispatch({type: SET_BANK_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const offset = page * onPage;
    let params = {
      offset: offset,
      limit: onPage,
    };

    Object.keys(values).forEach(key => values[key] && (params[key] = values[key]));

    const urlParams = new URLSearchParams(params).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/shared_files/cnt_get_all?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      const pageCount = Math.ceil(payload.cnt / onPage);
      dispatch({ type: SET_BANK_COUNT, payload: pageCount });
    }
  }
  dispatch({type: SET_BANK_LOADING, payload: false})
}

export const getComissionList = ({request_id, ...params}) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    
    const urlParams = new URLSearchParams(params).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/certification/commission?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMISSION_LIST, payload });
      dispatch(getComissionValues(request_id, !!params.is_expert))
    }
  }
};

export const getComissionValues = (requestId, is_expert) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const params = is_expert ? { is_expert } : {}
    const urlParams = new URLSearchParams(params).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/certification/request/${requestId}/confirm?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });

    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_COMMISSION_VALUES, payload });
    }
  }
};

export const createComissionApprove = (requestId, params) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    
    const response = await fetch(`${BACKEND_URL_PREFIX}/certification/request/${requestId}/confirm`, {
      method: "POST",
      ...buildDefaultHeaders(getState),
      body: JSON.stringify(params)
    });

    if (response.ok) {
      await dispatch(getComissionValues(requestId, params.is_expert));
      
      const comissionListLength = Array.from(new Set(getState().common.comission.list?.map(({MID}) => MID) || [])).length
      const comissionValuesLength = Array.from(new Set(getState().common.comission.values?.map(({mid}) => mid) || [])).length

      const statusCanChanged = comissionListLength <= comissionValuesLength || ( params.is_expert && comissionValuesLength > 3 ) 

      if (!statusCanChanged) return;

      const newReqStatus = !params.is_expert ? 3 : approved(getState) ? 6 : 7
      dispatch(updateRequestStatus(requestId, newReqStatus))
    }
  }
};

export const getUserInfo = (userId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/certification/request/get_info/${userId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_USER_INFO, payload });
    }
  }
};

export const toggleAcceptAward = (awardId, status) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    await fetch(`${BACKEND_URL_PREFIX}/awards/${awardId}`, {
      method: "PUT",
      body: JSON.stringify({status}),
      ...buildDefaultHeaders(getState)
    })
    const requestId = getState().common.currentRequest.id
    await dispatch(getCurrentRequest(requestId, 'withoutLoading'))
  }
}