import React, { useState } from 'react'
import Button from '../../components/Button'
import { useFormik } from 'formik'
import { getOrganizationOptions } from '../../utils/getOptions';
import { Form } from 'react-bootstrap'


export default function LoginForm({
    teacherMode,
    onSubmit,
    orgList,
    loading
}) {
  
    const [passVisible, setPassVisible] = useState(false)

    const authForm = useFormik({
        initialValues: {},
        onSubmit: onSubmit
      });

    return (
        <form onSubmit={authForm.handleSubmit}>
            {teacherMode &&
            <select
                name='organization'
                value={authForm.school}
                onChange={authForm.handleChange}
            >
                <option value=''>Выбрать учебное заведение</option>
                {getOrganizationOptions(orgList)}
            </select>
            }
            <input
                name='username'
                value={authForm.username}
                onChange={authForm.handleChange}
            />
            <input
                name='password'
                type={passVisible ? 'text' : 'password'}
                value={authForm.password}
                onChange={authForm.handleChange}
            />

            <div>
                <Form.Check
                    label='показать пароль'
                    style={{float: 'right'}}
                    checked={passVisible}
                    onChange={() => setPassVisible(!passVisible)}
                />
            </div>

            <Button
                type='submit'
                label={"Войти"}
                style={{marginTop: '10px'}}
                // submitting={loading}
            />
        </form>
    )
}
